<template>
<div class="passwordManagement page">
  <headers title="密码管理"></headers>
  <div class="scroll">
    <ul class="list">
      <li class="flexSpaceBetween" v-for="(item,index) in form_list" :key="index" @click="$router.push(item.path)">
        <div class="label">{{item.label}}</div>
        <van-icon name="arrow" class="arrowRight" size="20" color="rgb(197, 197, 197)"/>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "passwordManagement",
  components: {
    headers
  },
  data() {
    return {
      form_list: [
        {
          label: '修改密码',
          path: '/changePassword'
        },
        {
          label: '修改提现密码',
          path: '/withdrawPassword'
        }
      ],
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.passwordManagement {
  background: #f7f7fe;
  height: 100%;
  .scroll {
    .list {
      padding: 0 15px 16px;
      background: #fff;
      li {
        height: 55px;
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border-bottom: none;
        }
        .label {
          font-size: 15px;
        }
      }
    }
  }
}
</style>